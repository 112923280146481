import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
export default {
  __name: 'setting',
  setup(__props) {
    const show = ref(false);
    const onClickLeft = () => history.back();
    const actions = ref([{
      name: 'English',
      icon: '/image/lang/en.png',
      className: 'lang-list'
    }]);
    const onSelect = item => {
      show.value = false;
      console.log(item.name);
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        title: "Setup",
        "left-text": "",
        "left-arrow": "",
        onClickLeft: onClickLeft,
        border: false
      }), _createElementVNode("div", null, [_createVNode(_component_van_cell_group, null, {
        default: _withCtx(() => [_createVNode(_component_van_cell, {
          "is-link": "",
          title: "Language",
          value: "English",
          onClick: _cache[0] || (_cache[0] = $event => show.value = true)
        })]),
        _: 1
      }), _createVNode(_component_van_action_sheet, {
        class: "sheet-box",
        show: show.value,
        "onUpdate:show": _cache[1] || (_cache[1] = $event => show.value = $event),
        title: "Language setting",
        actions: actions.value,
        onSelect: onSelect,
        round: false
      }, null, 8, ["show", "actions"])])], 64);
    };
  }
};